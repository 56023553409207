import React, { useState, Fragment } from "react"
import styled from "@emotion/styled"
import Piece from "../components/Piece"
import dpoulet from "../images/donburipoulet.jpg"
import dvege from "../images/donburivege.jpg"
import soba from "../images/sobba.jpg"
import donburiJap from "../images/DonburiJap.png"
import nouillesJap from "../images/nouillesJap.png"

import {
  HalfTextContainer,
  CategoryName,
  ImageContainer,
  Jap,
} from "./shared/carte"

const Others = () => {
  const [selectedImage, setSelectedImage] = useState(dpoulet)
  const [selectedJap, setSelectedJap] = useState(donburiJap)

  const handlePictureChange = image => {
    setSelectedImage(image)
    if (image.includes(soba)) {
      setSelectedJap(nouillesJap)
    } else {
      setSelectedJap(donburiJap)
    }
  }

  return (
    <Fragment>
      <OtherContainer id="others">
        <HalfTextContainer className="starters">
          <CategoryName>DONBURIS</CategoryName>
          <Piece
            name="dpoulet"
            onClick={handlePictureChange.bind(this, dpoulet)}
            dishName={"DONBURI POULET"}
            price={"14,00 €"}
            text={
              "Base de riz, poulet mariné, carottes marinées, daikon, brocolis, jaune d'oeuf, gingembre, shitake, cebette, takuan taro, sésame blanc & noir"
            }
            selected={selectedImage.includes("donburipoulet")}
          />
          <Piece
            name="dvege"
            onClick={handlePictureChange.bind(this, dvege)}
            dishName={"DONBURI VÉGÉTARIEN"}
            price={"13,00 €"}
            text={
              "Base de riz, carottes marinées, daikon, brocolis, jaune d'oeuf, gingembre, okura, shitake, cebette, takuan taro, sésame blanc & noir"
            }
            selected={selectedImage.includes("donburivege")}
          />
          <CategoryName style={{ marginTop: "20px" }}>
            NOUILLES FROIDES
          </CategoryName>
          <Piece
            name="soba"
            onClick={handlePictureChange.bind(this, soba)}
            dishName={"NOUILLES SOBA"}
            price={"11,00 €"}
            text={
              "Nouille soba, oeuf mollet, cébette, algue nori, salade d'algues wakame"
            }
            selected={selectedImage.includes("sobba")}
          />
        </HalfTextContainer>
        <ImageContainer>
          <img
            className="bg-image"
            src={selectedImage}
            alt="Plats Donburis"
          ></img>
          <Jap>
            <img rel="preload" src={selectedJap} alt="Plats Donburis"></img>
          </Jap>
        </ImageContainer>
      </OtherContainer>
    </Fragment>
  )
}

const OtherContainer = styled.div`
  height: 100vh;
  display: flex;
  overflow: hidden;
  scroll-snap-align: start;

  @media screen and (max-width: 640px) {
    height: auto;
    flex-direction: column-reverse;
  }
`

export default Others
