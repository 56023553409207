import React, { useState, Fragment } from "react"
import scrollTo from "gatsby-plugin-smoothscroll"
import styled from "@emotion/styled"
import Piece from "../components/Piece"
import shoyu from "../images/ramenshoyu.jpg"
import yuzu from "../images/ramenyuzu.jpg"
import miso from "../images/ramenmiso.jpg"
import spicy from "../images/ramenspicy.jpg"
import truffe from "../images/ramentruffe.jpg"
import arrow from "../images/down.svg"
import jap from "../images/RamenJap.png"

import {
  CategoryContainer,
  HalfTextContainer,
  CategoryName,
  ImageContainer,
  Jap,
} from "./shared/carte"

const Arrow = styled.img`
  transition: all 0.2s;

  :hover {
    transform: scale(1.2);
  }
`

const Ramens = () => {
  const [selectedImage, setSelectedImage] = useState(shoyu)

  const handlePictureChange = image => {
    setSelectedImage(image)
  }
  return (
    <Fragment>
      <CategoryContainer
        id="ramens"
        style={{ position: "relative", scrollSnapAlign: "start" }}
      >
        <ImageContainer>
          <img
            className="bg-image"
            src={selectedImage}
            alt="Les Ramens de Hara Kiri"
          ></img>
          <Jap>
            <img rel="preload" src={jap} alt="Les Ramens de Hara Kiri"></img>
          </Jap>
        </ImageContainer>
        <HalfTextContainer className="starters">
          <CategoryName>RAMENS</CategoryName>
          <Piece
            name="shoyu"
            onClick={handlePictureChange.bind(this, shoyu)}
            dishName={"RAMEN SHOYU"}
            price={"14,00 €"}
            text={
              "Taré shoyu, tranche de chashu (porc ou poulet), naruto, pousse de bambou, mizuna, cebette, algue nori, nouilles tokyo wavy"
            }
            selected={selectedImage.includes("shoyu")}
          />
          <Piece
            name="yuzu"
            onClick={handlePictureChange.bind(this, yuzu)}
            dishName={"RAMEN SHOYU YUZU"}
            price={"15,00 €"}
            text={
              "Taré shoyu, tranche de chashu (porc ou poulet), zeste de yuzu, naruto, pousse de bambou, mizuna, cebette, algue nori, nouilles tokyo wavy"
            }
            selected={selectedImage.includes("yuzu")}
          />
          <Piece
            name="miso"
            onClick={handlePictureChange.bind(this, miso)}
            dishName={"RAMEN MISO"}
            price={"14,00 €"}
            text={
              "Taré miso avec une pointe de shoyu, tranche de chashu (porc ou poulet), naruto, pousse de bambou, mizuna, cebette, algue nori, nouilles tokyo straight"
            }
            selected={selectedImage.includes("miso")}
          />
          <Piece
            name="spicy"
            onClick={handlePictureChange.bind(this, spicy)}
            dishName={"RAMEN SPICY"}
            price={"15,00 €"}
            text={
              "Taré miso pimenté, tranche de chashu (porc ou poulet), pousse de bambou, poireaux, algue nori, oeuf mollet, nouilles"
            }
            selected={selectedImage.includes("spicy")}
          />
          <Piece
            name="truffe"
            onClick={handlePictureChange.bind(this, truffe)}
            dishName={"RAMEN TRUFFE NOIRE"}
            price={"19,00 €"}
            text={
              "Taré maison, tranche de chashu (porc ou poulet), copeaux de truffes, enoki, shitake,, cebette, algue nori, nouilles tokyo straight"
            }
            selected={selectedImage.includes("truffe")}
          />
        </HalfTextContainer>

        <button onClick={() => scrollTo("#others")} className="arrowLink">
          <Arrow src={arrow}></Arrow>
        </button>
      </CategoryContainer>
    </Fragment>
  )
}

export default Ramens
