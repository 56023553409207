import React from "react"
import styled from "@emotion/styled"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Ramens from "../components/Ramens"
import Others from "../components/Others"

const SnapContainer = styled.div`
  overflow: scroll;
  height: 100vh;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  @media screen and (max-width: 640px) {
    scroll-snap-type: none;
    scroll-behavior: none;
  }
`

const IndexPage = () => {
  return (
    <Layout headerColor={"black"}>
      <SEO title="Les plats du restaurant hara kiri ramen" />
      <SnapContainer id="platscontainer">
        <Ramens />
        <Others />
      </SnapContainer>
    </Layout>
  )
}

export default IndexPage
